import React from "react";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import { useLinks } from "../../lib/storyblok/useLinks";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import InvestorGridUi from "../../ui/InvestorGrid";
import ShareTicker from "../../ui/InvestorGrid/components/ShareTicker";
import { InvestorGridBlock } from "../../lib/storyblok/types/blocks/InvestorGridBlock";
import FinancialEvents from "../../ui/InvestorGrid/components/FinancialEvents";
import PressReleases from "../../ui/InvestorGrid/components/PressReleases";

const InvestorGrid: StoryblokBlockComponent<InvestorGridBlock> = ({
  locale,
  outerSpacing,
}) => {
  const { globalLinks } = useViewmodel();
  const resolveLink = useLinks();

  return (
    <InvestorGridUi outerSpacing={outerSpacing}>
      <ShareTicker locale={locale}></ShareTicker>
      <FinancialEvents
        locale={locale}
        browseAllHref={
          globalLinks.investorEventFilterPage &&
          resolveLink(globalLinks.investorEventFilterPage)
        }
      ></FinancialEvents>
      <PressReleases
        locale={locale}
        browseAllHref={
          globalLinks.pressreleaseFilterPage &&
          resolveLink(globalLinks.pressreleaseFilterPage)
        }
      />
    </InvestorGridUi>
  );
};

export default InvestorGrid;
