import React from "react";
import cn from "classnames";
import s from "../TabsGrid.module.scss";

interface TabLabelProps {
  index: number;
  title: string;
  isActive: boolean;
  onClick: () => void;
  isMobile?: boolean;
}

const TabLabel: React.FC<TabLabelProps> = ({
  index,
  title,
  isActive,
  onClick,
  isMobile,
}) => (
  <li
    className={cn(s.item, { [s.dropdownItem]: isMobile })}
    role={isMobile ? "option" : "presentation"}
  >
    <button
      tabIndex={index}
      className={cn(s.tabButton, {
        [s.isActive]: isActive,
        [s.dropdownButton]: isMobile,
      })}
      onClick={onClick}
      role={isMobile ? "option" : "tab"}
      aria-selected={isActive}
      aria-controls={`tab-panel-${index}`}
    >
      {title}
    </button>
  </li>
);

export default React.memo(TabLabel);
