import React, { useEffect } from "react";
import getConfig from "next/config";
import { useSubscriptionWidget } from "../../../hooks/mfn/useSubscriptionWidget";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
//import ss from "./Datablock.SharedStyles.module.scss";
import s from "./Datablock.Subscription.module.scss";

type Props = {
  locale: string;
  demo: boolean;
};

const Subscription: React.FC<Props> = ({ locale, demo }) => {
  const { publicRuntimeConfig } = getConfig();
  const { initializeSubscriptionWidget, cleanupSubscriptionWidget } =
    useSubscriptionWidget();
  const token = publicRuntimeConfig["DATABLOCKS_SUBSCRIPTION_TOKEN"];

  useEffect(() => {
    // Initialize and store the previous state
    const { existingMF } = initializeSubscriptionWidget(token, locale, demo);

    // Cleanup function
    return () => {
      cleanupSubscriptionWidget(existingMF);
    };
  }, [
    token,
    locale,
    demo,
    initializeSubscriptionWidget,
    cleanupSubscriptionWidget,
  ]);

  return <div id="subscribe-module" className={cn(c.narrow, s.subscription)} />;
};

export default Subscription;
