import Icon from "../../../ui/Icon";
import s from "./LinkWrapper.module.scss";
import { useIsExternalLink } from "../../../hooks/useIsExternalLink";
import { useEffect, useRef, useState } from "react";

type Props = {
  text?: React.ReactNode;
  href?: string;
  className?: string;
  variant?: string;
  style?: React.CSSProperties;
  color?: string;
  iconSize?: number;
};

const LinkWrapper = ({
  text,
  href,
  style,
  color,
  iconSize,
  ...rest
}: Props) => {
  const [computedColor, setComputedColor] = useState("var(--medium)");
  const [computedIconSize, setComputedIconSize] = useState(12);
  const { isExternalLink, shouldNofollow } = useIsExternalLink(href);
  const ref = useRef<HTMLAnchorElement>(null);
  const titleText = typeof text === "string" ? text : undefined;

  useEffect(() => {
    if (ref.current && ref.current.parentElement) {
      const parentStyle = getComputedStyle(
        ref.current.parentElement as Element
      );
      setComputedColor(
        (parentStyle.color.match(/255/g) || []).length === 3
          ? parentStyle.color
          : "var(--medium)"
      );
      setComputedIconSize(
        Number(parentStyle.fontSize.replace("px", "")) * 0.7 || 12
      );
    }
  }, []);

  switch (true) {
    case isExternalLink && shouldNofollow:
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer nofollow"
          ref={ref}
          style={{ ...style, color: color || computedColor }}
          title={titleText}
          {...rest}
        >
          {text}
          <Icon
            icon="arrow-up-right"
            width={iconSize || computedIconSize}
            color={color || computedColor}
            className={s.externalIcon}
          />
        </a>
      );

    case isExternalLink && !shouldNofollow:
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          ref={ref}
          style={{ ...style, color: color || computedColor }}
          title={titleText}
          {...rest}
        >
          {text}
          <Icon
            icon="arrow-up-right"
            width={iconSize || computedIconSize}
            color={color || computedColor}
            className={s.externalIcon}
          />
        </a>
      );

    default:
      return (
        <a
          href={href}
          target="_self"
          ref={ref}
          style={{ ...style, color: color || computedColor }}
          title={titleText}
          {...rest}
        >
          {text}
        </a>
      );
  }
};

export default LinkWrapper;
