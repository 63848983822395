// ui/Datablock/index.tsx
import React from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import s from "./Datablock.module.scss";
import ShareHolders from "./components/ShareHolders";
import InsidersTransactions from "./components/InsidersTransactions";
import Sharegraph from "./components/Sharegraph";
import Calendar from "./components/Calendar";
import Archive from "./components/Archive";
import Subscription from "./components/Subscription";
// import KeyFigures from "./components/KeyFigures";

type Props = {
  title: string;
  widgets: string;
  locale: string;
  outerSpacing: string;
};

const DatablockUi: React.FC<Props> = ({
  title,
  widgets,
  locale,
  outerSpacing,
}) => {
  const renderWidgetGroup = () => {
    switch (widgets) {
      case "shareholders":
        return <ShareHolders locale={locale} demo={false} />;
      case "insidersTransactions":
        return <InsidersTransactions locale={locale} demo={false} />;
      case "sharegraph":
        return <Sharegraph locale={locale} demo={false} />;
      case "financialCalendar":
        return <Calendar locale={locale} demo={false} />;
      case "archive":
        return <Archive locale={locale} demo={false} />;
      case "subscription":
        return <Subscription locale={locale} demo={false} />;
      /*
      case "keyFigures":
        return <KeyFigures locale={locale} />;
        */
      default:
        return null;
    }
  };

  return (
    <section className={cn(s.datablock, c.xWide, bs[outerSpacing])}>
      {title && <h2 className={s.title}>{title}</h2>}
      <div className={cn(s.datablockInner)}>{renderWidgetGroup()}</div>
    </section>
  );
};

export default DatablockUi;
