import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Button from "../../ui/Button";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";
import { TabsGridBlock } from "../../lib/storyblok/types/blocks/TabsGridBlock";
import TabsGridUi from "../../ui/TabsGrid";
import ImageVaultImage from "../ImageVaultImage";
import Image from "next/image";
import { AnimatePresence } from "framer-motion";

const TabsGrid: StoryblokBlockComponent<TabsGridBlock> = ({
  title,
  description,
  buttons,
  outerSpacing,
  width,
  tabs,
}) => {
  const resolveLink = useLinks();
  const [activeTab, setActiveTab] = React.useState(0);

  const tabIntroButtonCollection = React.useMemo(
    () =>
      buttons?.map((button) => (
        <Link
          key={button._uid}
          href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
          passHref
        >
          <Button
            variant={button.buttonType ?? "default"}
            href={resolveLink(button.buttonLink)}
          >
            {button.buttonText}
          </Button>
        </Link>
      )),
    [buttons, resolveLink]
  );

  const createItemButtonCollection = React.useCallback((itemButtons) => {
    if (itemButtons && itemButtons.length > 0) {
      const button = itemButtons[0];
      return (
        <Button variant={button.buttonType ?? "default"} size="medium">
          {button.buttonText}
        </Button>
      );
    }
    return null;
  }, []);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <TabsGridUi
      title={title}
      description={description}
      buttons={tabIntroButtonCollection}
      outerSpacing={outerSpacing}
      width={width}
    >
      <TabsGridUi.TabLabels activeIndex={activeTab}>
        {tabs.map((tab, i) => (
          <TabsGridUi.TabLabel
            key={tab._uid}
            index={i}
            title={tab.tabTitle}
            isActive={activeTab === i}
            onClick={() => handleTabClick(i)}
          />
        ))}
      </TabsGridUi.TabLabels>
      <AnimatePresence>
        {tabs.map(
          (tab, j) =>
            activeTab === j && (
              <TabsGridUi.Tab key={tab._uid} isActive={activeTab === j}>
                <TabsGridUi.TabHeader
                  description={tab.gridDescription}
                  index={j}
                />
                <TabsGridUi.TabGrid layout={tab.layout}>
                  {tab.items.map((item, k) => (
                    <TabsGridUi.TabGridItem
                      key={item._uid}
                      title={item.title}
                      description={item.description}
                      buttons={createItemButtonCollection(item.buttons)}
                      visualType={item.visualType}
                      href={
                        item.buttons?.[0]?.buttonLink
                          ? resolveLink(item.buttons[0].buttonLink)
                          : undefined
                      }
                      image={
                        item.image && (
                          <ImageVaultImage
                            image={item.image}
                            layout="fill"
                            sizes="720px"
                            objectFit={item.cover ? "cover" : "contain"}
                            priority
                          />
                        )
                      }
                      video={
                        item.video?.filename && (
                          <TabsGridUi.VideoPlayer
                            key={item.video.id}
                            videoUrl={item.video.filename}
                            isActiveTab={activeTab === j}
                          />
                        )
                      }
                      icon={
                        item.icon && (
                          <Image
                            src={`/svg/icons/${item.icon}.svg`}
                            width={40}
                            height={40}
                            alt=""
                          />
                        )
                      }
                      cover={item.cover}
                      overlayStyle={item.overlayStyle}
                      overlayOpacity={item.overlayOpacity}
                      index={k}
                    />
                  ))}
                </TabsGridUi.TabGrid>
              </TabsGridUi.Tab>
            )
        )}
      </AnimatePresence>
    </TabsGridUi>
  );
};

export default TabsGrid;
