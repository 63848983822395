import React from "react";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "../TabsGrid.module.scss";
import RichText from "../../Common/RichText";
import type { StoryblokRichTextFieldtype } from "../../../lib/storyblok/types/fieldtypes/richText";

type Props = {
  description: StoryblokRichTextFieldtype;
  index: number;
};

const TabHeader = ({ description, index }: Props) => (
  <div className={cn(s.header, c.narrow)} tabIndex={index}>
    <RichText content={description} />
  </div>
);

export default TabHeader;
