import { StartPageStory } from "../../lib/storyblok/types/stories/StartPageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import Button from "../../ui/Button";
import StartPageCTA from "../../ui/StartPageCTA";
import { DynamicBlock, DynamicBlocks } from "../DynamicBlock";
import ImageVaultImage from "../ImageVaultImage";
import HeroBlock from "../Nestable/Hero";
import { Container } from "../../ui/ContentGrid";
import { useLinks } from "../../lib/storyblok/useLinks";
import SliderBlock from "../Nestable/Slider";

type VM = ViewModel<StartPageStory>;

const SimpleContentPage: StoryblokStoryComponent<VM> = ({ story }) => {
  const resolveLink = useLinks();
  const {
    hero,
    slider,
    featuredTabs,
    logoList,
    productCta,
    contentGrids,
    ctaBlocks,
  } = story.content;
  return (
    <>
      {slider?.[0] ? (
        <SliderBlock {...slider[0]} />
      ) : (
        hero?.[0] && <HeroBlock {...hero[0]} isStartPage />
      )}
      <DynamicBlocks blocks={[...(featuredTabs || []), ...(logoList || [])]} />
      {productCta && productCta[0] && (
        <StartPageCTA
          title={productCta[0]?.title}
          preamble={productCta[0]?.preamble}
          buttons={productCta[0]?.buttons?.map((button) => (
            <Button
              key={button._uid}
              href={resolveLink(button.buttonLink)}
              variant={button.buttonType}
            >
              {button.buttonText}
            </Button>
          ))}
          image={
            <ImageVaultImage
              priority
              image={productCta[0].image}
              layout="responsive"
              sizes={"720px"}
            />
          }
        />
      )}
      <Container>
        {contentGrids?.map((grid) => {
          grid.isStartpage = true;
          return <DynamicBlock key={grid._uid} content={grid} overrides={""} />;
        })}
      </Container>
      <DynamicBlocks blocks={ctaBlocks} />
    </>
  );
};

export default SimpleContentPage;
