import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./InvestorGrid.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import ShareTicker from "./components/ShareTicker";
import FinancialEvents from "./components/FinancialEvents";
import PressReleases from "./components/PressReleases";

type Props = {
  outerSpacing: string;
  children: ReactNode;
};

const subComponents = { ShareTicker, FinancialEvents, PressReleases };
type SubComponents = typeof subComponents;

const InvestorGrid: CWS<Props, SubComponents> = ({
  outerSpacing,
  children,
}) => {
  return (
    <section className={cn(s.insvetorGrid, c.xWide, bs[outerSpacing])}>
      <div className={cn(s.investorGridInner)}>{children}</div>
    </section>
  );
};

InvestorGrid.ShareTicker = ShareTicker;
InvestorGrid.FinancialEvents = FinancialEvents;
InvestorGrid.PressReleases = PressReleases;

export default InvestorGrid;
