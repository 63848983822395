import { ViewModel } from "../../lib/viewModel/createViewModel";
import { ArticlePageStory } from "../../lib/storyblok/types/stories/ArticlePageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { resolveStoryImage } from "../../lib/utils/resolveStoryImage";
import { DynamicBlocks } from "../DynamicBlock";
import { useTranslation } from "next-i18next";
import { useRelations } from "../../lib/storyblok/useRelations";
import { useLinks } from "../../lib/storyblok/useLinks";
import Image from "next/image";

import ArticleHeadingUI from "../../ui/ArticleHeading";
import AuthorsUi from "../../ui/Authors";
import ImageVaultImage from "../ImageVaultImage";
import { Author } from "../../lib/storyblok/types/stories/Author";
import TagsAndShareWrapper from "../../ui/TagsAndShareWrapper";
import Tag from "../Tag";
import WeChat from "../../public/images/wechat.jpg";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import ShareItems from "../../ui/Share/components/ShareItems";
import EditorialWithoutSpacing from "../Nestable/EditorialWithoutSpacing";
import CTAForm from "../Nestable/CtaForm";
import { resolveDate } from "../../lib/utils/resolveDate";

type VM = ViewModel<ArticlePageStory>;

const ArticlePage: StoryblokStoryComponent<VM> = ({ story }) => {
  const resolveLink = useLinks();

  const { getRelation } = useRelations();
  const { t } = useTranslation(["articlepage", "common"]);

  const {
    pageTitle,
    author,
    authors,
    collaborators,
    createdAt,
    readTime,
    body,
    ctaForm,
    contentGrid,
    tagsProductCategories,
    tagsSolutions,
    otherTags,
  } = story.content;
  const { globalLinks, locale } = useViewmodel();

  return (
    <>
      <ArticleHeadingUI
        title={pageTitle}
        author={author}
        createdAt={resolveDate(createdAt, locale)}
        readTime={readTime}
        byLabel={t("by")}
        minutesLabel={t("minutes")}
      >
        <ShareItems />
      </ArticleHeadingUI>
      <DynamicBlocks
        blocks={body}
        overrides={{ editorial: EditorialWithoutSpacing }}
      />
      <TagsAndShareWrapper>
        <TagsAndShareWrapper.TagsWrapper>
          {tagsProductCategories?.map((tag) => {
            const baseUrl = globalLinks.mainBlogFilterPage
              ? resolveLink(globalLinks.mainBlogFilterPage)
              : "";
            const url = `${baseUrl}?categories=${tag}`;
            return <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
          })}
          {tagsSolutions?.map((tag) => {
            const baseUrl = globalLinks.mainBlogFilterPage
              ? resolveLink(globalLinks.mainBlogFilterPage)
              : "";
            const url = `${baseUrl}?solutions=${tag}`;
            return <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
          })}
          {otherTags?.map((tag) => {
            const baseUrl = globalLinks.mainBlogFilterPage
              ? resolveLink(globalLinks.mainBlogFilterPage)
              : "";
            const url = `${baseUrl}?otherTags=${tag}`;
            return <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
          })}
        </TagsAndShareWrapper.TagsWrapper>

        <TagsAndShareWrapper.ShareWrapper
          title={t("share", { ns: "common" })}
          weChat={
            locale === "zh" && (
              <Image src={WeChat} quality={100} sizes="100vw" alt="WeChat" />
            )
          }
        >
          <ShareItems />
        </TagsAndShareWrapper.ShareWrapper>
      </TagsAndShareWrapper>
      {authors?.length > 0 && (
        <AuthorsUi title={t("written-by")}>
          {authors.map((author) => {
            const story = getRelation<Author>(author);

            if (story) {
              const { uuid, content, name } = story;
              return (
                <AuthorsUi.Author
                  key={uuid}
                  image={
                    content.image.item && (
                      <ImageVaultImage
                        sizes="33vw"
                        image={resolveStoryImage(content)}
                        layout="fill"
                      />
                    )
                  }
                  name={name}
                  rolesAndCompany={content.rolesAndCompany}
                  description={content.description}
                  facebookLink={
                    content.facebookLink?.url &&
                    resolveLink(content.facebookLink)
                  }
                  linkedinLink={
                    content.linkedinLink?.url &&
                    resolveLink(content.linkedinLink)
                  }
                  twitterLink={
                    content.twitterLink?.url && resolveLink(content.twitterLink)
                  }
                  link={content.link?.url && resolveLink(content.link)}
                  showMoreLabel={t("show-more", { ns: "common" })}
                  showLessLabel={t("show-less", { ns: "common" })}
                />
              );
            }
          })}
        </AuthorsUi>
      )}
      {collaborators?.length > 0 && (
        <AuthorsUi title={t("collaboration")} dark>
          {collaborators.map((collaborator) => {
            const story = getRelation<Author>(collaborator);

            if (story) {
              const { uuid, content } = story;

              return (
                <AuthorsUi.Author
                  key={uuid}
                  image={
                    content.image.item && (
                      <ImageVaultImage
                        sizes="33vw"
                        image={resolveStoryImage(content)}
                        layout="fill"
                      />
                    )
                  }
                  name={content.name}
                  rolesAndCompany={content.rolesAndCompany}
                  description={content.description}
                  facebookLink={
                    content.facebookLink?.url &&
                    resolveLink(content.facebookLink)
                  }
                  linkedinLink={
                    content.linkedinLink?.url &&
                    resolveLink(content.linkedinLink)
                  }
                  twitterLink={
                    content.twitterLink?.url && resolveLink(content.twitterLink)
                  }
                  link={content.link?.url && resolveLink(content.link)}
                  showMoreLabel={t("show-more", { ns: "common" })}
                  showLessLabel={t("show-less", { ns: "common" })}
                />
              );
            }
          })}
        </AuthorsUi>
      )}
      <DynamicBlocks blocks={[...(ctaForm ? ctaForm : []), ...contentGrid]} />
      <>
        <div id="subscribe"></div>
        <CTAForm
          title={t("blog-subscribe-title")}
          formUrl={t("blog-subscribe-formUrl")}
          text={t("blog-subscribe-description")}
          _uid={""}
          component={"ctaForm"}
          _editable={""}
          spacing={"both"}
          copy={""}
          image={{
            _uid: "",
            item: undefined,
            plugin: "image-vault",
          }}
          style={"gray"}
          divider={"none"}
        />
      </>
    </>
  );
};

export default ArticlePage;
