import React from "react";
import getConfig from "next/config";
import { resolveDate } from "../../../lib/utils/resolveDate";
import { useTranslation } from "next-i18next";
import cn from "classnames";
import s from "../InvestorGrid.module.scss";
import { usePressReleasesFeed } from "../../../hooks/mfn/usePressReleasesFeed";
import type { NewsItem } from "../../../hooks/mfn/usePressReleasesFeed";

type Props = {
  locale: string;
  browseAllHref?: string;
};

const PressReleases: React.FC<Props> = ({ browseAllHref, locale }) => {
  const { t } = useTranslation();

  const { publicRuntimeConfig } = getConfig();
  const tokenIR = publicRuntimeConfig["MFN_IR_FEED_TOKEN"];
  const tokenPR = publicRuntimeConfig["MFN_PR_FEED_TOKEN"];
  const { newsItems, isLoading, error } = usePressReleasesFeed(
    tokenIR,
    tokenPR,
    locale
  );

  const generateItemUrl = (item: NewsItem) => {
    const baseUrl =
      locale === "sv"
        ? "/sv/media/pressmeddelanden"
        : "/newsroom/press-releases";
    const slug = item.content.slug;
    const type = item.feedSource;
    return `${baseUrl}?s=${slug}&t=${type}&lang=${locale}`;
  };

  if (error) {
    return (
      <div className={cn(s.item, s.pressReleases)}>
        <div className={s.error}>Unable to load press releases</div>
      </div>
    );
  }

  if (isLoading || !newsItems.length) {
    return (
      <div className={cn(s.item, s.pressReleases)}>
        <div className={s.loading}>{t("loading...")}</div>
      </div>
    );
  }

  return (
    <div className={cn(s.item, s.pressReleases)}>
      <div className={s.listHeading}>
        <h3>{t("latest-press-releases")}</h3>
        {browseAllHref && (
          <a
            className={s.browseAll}
            href={browseAllHref}
            title={t("latest-press-releases")}
          >
            {t("browse-all")}
          </a>
        )}
      </div>
      <ul className={s.list}>
        {newsItems.map((item) => (
          <li key={item.news_id} className={s.listItem}>
            <time dateTime={resolveDate(item.content.publish_date)}>
              {resolveDate(item.content.publish_date, locale)}
            </time>
            <a href={generateItemUrl(item)} className={s.title}>
              {item.content.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PressReleases;
