import React, { useEffect, useMemo } from "react";
import getConfig from "next/config";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
//import ss from "./Datablock.SharedStyles.module.scss";
import s from "./Datablock.Sharegraph.module.scss";

type Props = {
  locale: string;
  demo: boolean;
};

const Sharegraph: React.FC<Props> = ({ locale, demo }) => {
  const { publicRuntimeConfig } = getConfig();
  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const widgetConfigs = useMemo(
    () => [
      {
        widget: "stock-chart",
        widgetId: "stock-chart",
        token: publicRuntimeConfig["DATABLOCKS_STOCK_CHART_TOKEN"],
        label: locale === "en" ? "Stock graph" : "Aktiegraf",
      },
      /*
      {
        widget: "stock-table",
        widgetId: "stock-table",
        token: publicRuntimeConfig["DATABLOCKS_STOCK_TABLE_TOKEN"],
        label: locale === "en" ? "Performance" : "Prestanda",
      },
      */
      {
        widget: "stock-calculator",
        widgetId: "stock-calculator",
        token: publicRuntimeConfig["DATABLOCKS_SHARE_CALCULATOR_TOKEN"],
        label: locale === "en" ? "Share calculator" : "Aktiekalkylator",
      },
    ],
    [publicRuntimeConfig, locale]
  );

  useEffect(() => {
    initializeWidgets(widgetConfigs, locale, demo);
    return () => cleanupWidgets(widgetConfigs, locale);
  }, [locale, demo, widgetConfigs, initializeWidgets, cleanupWidgets]);

  return (
    <div className={s.sharegraphContainer}>
      {widgetConfigs.map((config) => (
        <div
          key={config.widgetId}
          id={`${config.widgetId}-${locale}`}
          className={cn(c.xWide, s.sharegraph)}
        />
      ))}
    </div>
  );
};

export default Sharegraph;
