import React from "react";
import Image from "next/image";
import { render } from "storyblok-rich-text-react-renderer";
import { markdownToRichtext } from "storyblok-markdown-richtext";
import LinkWrapper from "../../../components/Nestable/LinkWrapper/LinkWrapper";
import { isStoryblokSlug } from "../../../lib/routing/isStoryblokSlug";
import { resolveSlug } from "../../../lib/utils/resolveSlug";
import cn from "classnames";
import s from "./RichText.module.scss";

import type { RichTextContent } from "../../../lib/storyblok/types/fieldtypes/richText";

interface Props {
  content: RichTextContent;
  className?: string;
}

interface StoryblokProps extends Record<string, unknown> {
  _uid: string;
}

const RichText: React.FC<Props> = ({ content, className }) => {
  if (!content) return null;

  const defaultBlokResolver = (
    name: string,
    props: StoryblokProps,
    children?: React.ReactNode
  ) => {
    switch (name) {
      case "heading": {
        const level = props.level as number;
        if (!level) return null;

        const HeadingTag = `h${level}` as keyof JSX.IntrinsicElements;
        return (
          <HeadingTag
            id={props.id as string}
            className={cn(s.heading, props.class as string, `heading-${level}`)}
          >
            {children}
          </HeadingTag>
        );
      }
      case "link": {
        const href = props.href as string;
        if (!href) return null;

        const isSbSlug = isStoryblokSlug(href);
        const finalHref = isSbSlug ? resolveSlug(href) : href;

        return <LinkWrapper href={finalHref} text={children} />;
      }
      case "image": {
        const src = props.src as string;
        if (!src) return null;

        return (
          <figure className={s.imageWrapper}>
            <div className={s.imageContainer}>
              <Image
                src={src}
                alt={(props.alt as string) || ""}
                title={props.title as string}
                width={(props.width as number) || 800}
                height={(props.height as number) || 600}
                className={s.image}
                loading={(props.loading as "lazy" | "eager") || "lazy"}
              />
            </div>
          </figure>
        );
      }
      case "textStyle": {
        const styles: React.CSSProperties = {
          color: props.color as string,
          backgroundColor: props.backgroundColor as string,
          textAlign: props.textAlign as "left" | "center" | "right" | "justify",
          fontFamily: props.fontFamily as string,
          fontSize: props.fontSize as string,
          fontWeight: props.fontWeight as string | number,
          lineHeight: props.lineHeight as string | number,
          letterSpacing: props.letterSpacing as string,
          textDecoration: props.textDecoration as string,
          textTransform: props.textTransform as
            | "none"
            | "capitalize"
            | "uppercase"
            | "lowercase",
        };

        return <span style={styles}>{children}</span>;
      }
      default:
        return null;
    }
  };

  const nodeResolvers = {
    paragraph: (children: React.ReactNode) => (
      <p className={s.paragraph}>{children}</p>
    ),
    bullet_list: (children: React.ReactNode) => (
      <ul className={s.bulletList}>{children}</ul>
    ),
    ordered_list: (children: React.ReactNode) => (
      <ol className={s.orderedList}>{children}</ol>
    ),
    list_item: (children: React.ReactNode) => (
      <li className={s.listItem}>{children}</li>
    ),
    code_block: (children: React.ReactNode, props: { class: string }) => (
      <pre className={cn(s.codeBlock, props.class)}>
        <code>{children}</code>
      </pre>
    ),
    blockquote: (children: React.ReactNode) => (
      <blockquote className={s.blockquote}>{children}</blockquote>
    ),
    horizontal_rule: () => <hr className={s.hr} />,
    hard_break: () => <br />,
  };

  const renderContent = () => {
    if (typeof content === "string") {
      const markdownConverted = markdownToRichtext(content);
      return render(markdownConverted, {
        defaultBlokResolver,
        nodeResolvers,
      });
    }

    return render(content, {
      defaultBlokResolver,
      nodeResolvers,
    });
  };

  return <div className={cn(s.richText, className)}>{renderContent()}</div>;
};

export default RichText;
