import React, { useEffect, useMemo, useState } from "react";
import getConfig from "next/config";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import ss from "./Datablock.SharedStyles.module.scss";
import s from "./Datablock.ShareHolders.module.scss";

type Props = {
  locale: string;
  demo: boolean;
};

const ShareHolders: React.FC<Props> = ({ locale, demo }) => {
  const { publicRuntimeConfig } = getConfig();
  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const widgetConfigs = useMemo(
    () => [
      {
        widget: "owner-list",
        widgetId: "owner-list",
        token: publicRuntimeConfig["DATABLOCKS_OWNER_LIST_TOKEN"],
        label: locale === "en" ? "Owner list" : "Ägarlista",
      },
      {
        widget: "owner-country-dist",
        widgetId: "owner-country-dist",
        token: publicRuntimeConfig["DATABLOCKS_OWNER_COUNTRY_DIST_TOKEN"],
        label: locale === "en" ? "Country distribution" : "Land distribution",
      },
      {
        widget: "owner-holding-dist",
        widgetId: "owner-holding-dist",
        token: publicRuntimeConfig["DATABLOCKS_OWNER_HOLDING_DIST_TOKEN"],
        label:
          locale === "en" ? "Holding distribution" : "Innehav distribution",
      },
      {
        widget: "owner-type-dist",
        widgetId: "owner-type-dist",
        token: publicRuntimeConfig["DATABLOCKS_OWNER_TYPE_DIST_TOKEN"],
        label: locale === "en" ? "Type distribution" : "Typ distribution",
      },
      {
        widget: "owner-concentration",
        widgetId: "owner-concentration",
        token: publicRuntimeConfig["DATABLOCKS_OWNER_CONCENTRATION_TOKEN"],
        label: locale === "en" ? "Concentration" : "Koncentration",
      },
    ],
    [publicRuntimeConfig, locale]
  );

  const [activeTab, setActiveTab] = useState(widgetConfigs[0].widgetId);

  useEffect(() => {
    initializeWidgets(widgetConfigs, locale, demo);
    return () => cleanupWidgets(widgetConfigs, locale);
  }, [locale, demo, widgetConfigs, initializeWidgets, cleanupWidgets]);

  return (
    <div className={ss.tabsContainer}>
      <div className={ss.tabsList} role="tablist">
        {widgetConfigs.map((config) => (
          <button
            key={config.widgetId}
            role="tab"
            aria-selected={activeTab === config.widgetId}
            aria-controls={`panel-${config.widgetId}`}
            id={`tab-${config.widgetId}`}
            className={cn(ss.tabButton, {
              [ss.active]: activeTab === config.widgetId,
            })}
            onClick={() => setActiveTab(config.widgetId)}
          >
            {config.label}
          </button>
        ))}
      </div>
      <div className={ss.tabPanels}>
        {widgetConfigs.map((config) => (
          <div
            key={config.widgetId}
            role="tabpanel"
            id={`panel-${config.widgetId}`}
            aria-labelledby={`tab-${config.widgetId}`}
            hidden={activeTab !== config.widgetId}
            className={ss.tabPanel}
          >
            <div
              id={`${config.widgetId}-${locale}`}
              className={cn(c.wide, s.shareholders)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShareHolders;
