import React, { ReactNode, useState } from "react";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import useMediaQuery from "../../../hooks/useMatchMedia";
import RichText from "../../Common/RichText";
import type { StoryblokRichTextFieldtype } from "../../../lib/storyblok/types/fieldtypes/richText";
import s from "../TabsGrid.module.scss";
import BlockBackground from "../../Common/BlockBackground/BlockBackground";

type Props = {
  title: string;
  description: StoryblokRichTextFieldtype;
  href?: string;
  buttons?: ReactNode;
  visualType: string;
  image?: ReactNode;
  video?: ReactNode;
  icon?: ReactNode;
  index: number;
  cover: boolean;
  overlayStyle?: string;
  overlayOpacity?: { value: number };
};

const itemVariants = {
  hidden: { opacity: 0, y: 0 },
  visible: { opacity: 1, y: 0 },
};

const buttonVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const TabGridItem = ({
  title,
  description,
  href,
  buttons,
  visualType,
  image,
  video,
  icon,
  index,
  cover,
  overlayStyle,
  overlayOpacity,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const isDesktopDown = useMediaQuery("(min-width: 1024px)");
  const initialOpacity = overlayOpacity?.value ?? 100;
  const hoverOpacity = Math.min(initialOpacity + 15, 100);

  const hoverProps = isDesktopDown
    ? {
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
      }
    : {};

  const contentStyle = cn(s.content, {
    [s.inverted]:
      overlayStyle &&
      !["white", "gray", "none"].includes(overlayStyle) &&
      overlayStyle !== "",
    [s.default]:
      !overlayStyle || ["white", "gray", "none"].includes(overlayStyle),
  });

  const renderVisual = () => {
    const visualContent = (
      <motion.div
        className={s.visualWrapper}
        animate={{ scale: isHovered && isDesktopDown ? 1.05 : 1 }}
        transition={{ duration: 0.3 }}
      >
        {(() => {
          switch (visualType) {
            case "image":
              return <div className={s.imageContainer}>{image}</div>;
            case "video":
              return video;
            case "icon":
              return <div className={s.iconContainer}>{icon}</div>;
            default:
              return null;
          }
        })()}
      </motion.div>
    );

    return visualContent;
  };

  const content = (
    <div className={cn(s.content, contentStyle)}>
      <div className={s.contentInner}>
        {title && <h4 className={s.title}>{title}</h4>}
        {description && (
          <RichText content={description} className={s.description} />
        )}
        <AnimatePresence>
          {buttons && (isHovered || !isDesktopDown) && (
            <motion.div
              className={s.buttons}
              variants={buttonVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ duration: 0.3 }}
            >
              {buttons}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );

  const innerContent = (
    <>
      {renderVisual()}
      {overlayStyle && (
        <BlockBackground
          style={overlayStyle}
          opacity={isHovered && isDesktopDown ? hoverOpacity : initialOpacity}
        />
      )}
      {content}
    </>
  );

  return (
    <motion.li
      className={cn(s.gridItem, { [s.gridItemCover]: cover })}
      variants={itemVariants}
      transition={{ duration: 0.3 }}
      tabIndex={index}
      {...hoverProps}
    >
      {href ? (
        <a href={href} title={title} className={s.gridItemInner}>
          {innerContent}
        </a>
      ) : (
        <div className={s.gridItemInner}>{innerContent}</div>
      )}
    </motion.li>
  );
};

export default TabGridItem;
