import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import DatablockUi from "../../ui/Datablock";
import { DatablockBlock } from "../../lib/storyblok/types/blocks/DatablockBlock";

const Datablock: StoryblokBlockComponent<DatablockBlock> = ({
  title,
  widgets,
  locale,
  outerSpacing,
}) => {
  return (
    <DatablockUi
      title={title}
      widgets={widgets}
      locale={locale}
      outerSpacing={outerSpacing}
    />
  );
};

export default Datablock;
