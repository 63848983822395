import { useCallback, useState, useEffect } from "react";

// Simplified to only what's needed for the component
export interface Content {
  title: string;
  slug: string;
  publish_date: string;
}

export interface NewsItem {
  news_id: string;
  content: Content;
  feedSource: "IR" | "PR";
}

interface NewsResponse {
  items: {
    news_id: string;
    content: Content;
  }[];
}

export const usePressReleasesFeed = (
  tokenIR: string,
  tokenPR: string,
  locale: string
) => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNewsData = useCallback(async () => {
    try {
      const [irResponse, prResponse] = await Promise.all([
        fetch(`https://feed.mfn.se/v1/feed/${tokenIR}.json?lang=${locale}`),
        fetch(`https://feed.mfn.se/v1/feed/${tokenPR}.json?lang=${locale}`),
      ]);

      if (!irResponse.ok || !prResponse.ok) {
        throw new Error(
          `HTTP error! status: ${
            !irResponse.ok ? irResponse.status : prResponse.status
          }`
        );
      }

      const [irData, prData] = await Promise.all([
        irResponse.json() as Promise<NewsResponse>,
        prResponse.json() as Promise<NewsResponse>,
      ]);

      // Map only required fields and add feedSource
      const irItems = irData.items.map(({ news_id, content }) => ({
        news_id,
        content,
        feedSource: "IR" as const,
      }));

      const prItems = prData.items.map(({ news_id, content }) => ({
        news_id,
        content,
        feedSource: "PR" as const,
      }));

      const allItems = [...irItems, ...prItems]
        .sort(
          (a, b) =>
            new Date(b.content.publish_date).getTime() -
            new Date(a.content.publish_date).getTime()
        )
        .slice(0, 3); // Show 3 releases

      setNewsItems(allItems);
      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to fetch news data"
      );
      setNewsItems([]);
    } finally {
      setIsLoading(false);
    }
  }, [tokenIR, tokenPR, locale]);

  useEffect(() => {
    fetchNewsData();
    const intervalId = setInterval(fetchNewsData, 300000); // 5 minutes
    return () => clearInterval(intervalId);
  }, [fetchNewsData]);

  return {
    newsItems,
    isLoading,
    error,
  };
};
